import type { IPointData } from 'pixi.js';

import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import type { SlotId } from './config';
import type { ReelSetType } from './gql/query';
import type Animation from './slotMachine/animations/animation';
import type Tween from './slotMachine/animations/tween';
import type { BgSkin } from './slotMachine/background/background';
import type { SlotMachineState } from './slotMachine/config';
import type { IWinLine, Icon, LineSet } from './slotMachine/d';
import type { FreeSpinsState } from './slotMachine/freeSpinsView';
import type { BingoLine } from './slotMachine/freeSpinsView/config';

export interface IBalance {
  amount: number;
  currency: string;
  metadata?: IMetadata;
}

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: IBalance;
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
  coinCode?: string;
}

export interface IMetadata {
  type: string;
  data: {
    messageTitle: string;
    messageBody: string;
  };
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface ISettledBet {
  balance: {
    placed: IBalance;
    settled: IBalance;
  };
  bet: {
    coinAmount: number;
    coinValue: number;
    data: {
      features: {
        diamonds: {
          position: number;
          score: number;
        }[];
      };
      bonuses: UserBonus[];
    };
    id: string;
    lineSet: LineSet;
    reelSet: ReelSet;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[]; // only fe
    };
    reelSetId: string;
  };
  paylines: IWinLine[];
  rewards: BetReward[];
}
export type BetRewardType = 'BetBonusReward' | 'BetCoinReward' | 'ReplayBonusReward';
export type BetBonusReward = {
  __typename: BetRewardType;
  userBonusId: string;
  userBonus?: UserBonus;
};

export type BetCoinReward = {
  __typename: BetRewardType;
  amount: number;
};

export type BonusType = 'FREE_SPIN' | 'SPECIAL_ROUND' | 'GAME_MODE' | 'FREE_BET_ROUND' | '';
export type BetReward = BetBonusReward | BetCoinReward;
export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
};
export type BonusRoundData = {
  remainingRounds: number;
  landingDiamonds: { position: number; score: number }[];
};
export type BonusData = {
  legend: BonusRoundData[];
  multipliers: {
    rows: [number, number, number];
    columns: [number, number, number, number, number];
  };
  rewardAmount: number;
  frbReferenceId: string | null;
};
export type UserBonus = {
  bonusId: string;
  id: string;
  isActive: boolean;
  currentRound: number;
  totalRounds: number;
  betId: string;
  rounds: number;
  reelSetId: string;
  bonus: Bonus;
  coinAmount: number;
  totalWinAmount: number;
  coinAmount: number;
  coinValue: number;
  status: BonusStatus;
  data: BonusData;
  isFreeBet?: boolean;
};

export type FreeRoundBonus = {
  id: string;
  bonusId: string;
  status: BonusStatus;
  coinAmount: number;
  coinValue: number;
  rounds: number;
  //roundsPlayed: number;
  totalWinAmount: number;
  isActive: boolean;
  currentRound: number;
};

export type Bonus = {
  id: string;
  slotId: string;
  reelSetId: string;
  type: BonusType;
  title?: string;
  description?: string;
  lineSetId: string | null;
  coinAmount: number;
  purchasable: boolean;
  rounds: number;
  data: unknown;
};
export enum GameMode {
  REGULAR,
  FREE_SPINS,
  BUY_FEATURE,
}
export const reelSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '15f25cea-2e98-4f00-8dfc-6c0c45fc7923',
  [GameMode.FREE_SPINS]: 'daa25f49-a80e-460e-93fc-f3491b10255a',
  [GameMode.BUY_FEATURE]: 'f9fa8800-c621-404b-a08b-b3483dba6aa0',
};

export const bonusIds: Record<GameMode, string> = {
  [GameMode.FREE_SPINS]: '5691069c-dcb5-40d9-a6e6-aad012f4c7e0',
  [GameMode.BUY_FEATURE]: '8801693e-3a6a-4ec4-9672-058983edfa2d',
};

export const freeRoundBonusId = '4990de2e-5163-4d1c-9388-f25f1616c6b1';

export const lineSets: Record<GameMode, string> = {
  [GameMode.REGULAR]: '90bfec88-78f5-4a5b-9335-77ba29219d44',
  [GameMode.FREE_SPINS]: '90bfec88-78f5-4a5b-9335-77ba29219d44',
  [GameMode.BUY_FEATURE]: '90bfec88-78f5-4a5b-9335-77ba29219d44',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
}
export interface GetUserBonusesInput {
  id: string;
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    PIXI: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    Howler: {
      _spriteBaseUrl: string;
    };
    remoteStorage: RemoteStorage;
  }
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: number;
  currentSpin: number;
};

export type BaseMessageBannerProps = {
  preventDefaultDestroy?: boolean;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type MessageFreeSpinsBannerProps = BaseMessageBannerProps;

export type MessageWinBannerProps = BaseMessageBannerProps & {
  title: string;
  totalWin: string;
  totalWinAmount: number;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  ADD_WIN_AMOUNT = 'addWinAmount',
  ANTICIPATION_STARTS = 'anticipationStarts',
  ANTICIPATION_ANIMATIONS_START = 'anticipationAnimationsStart',
  ANTICIPATION_ANIMATIONS_END = 'anticipationAnimationsEnd',
  BREAK_SPIN_ANIMATION = 'breakSpinAnimation',
  COUNT_UP_END = 'countUpEnd',
  CHANGE_MODE = 'changeMode',
  CHANGE_REEL_SET = 'changeReelSet',
  CREATE_FREE_SPINS_TITLE = 'createFreeSpinsTitle',
  CREATE_MESSAGE_BANNER = 'createMessageBanner',
  CREATE_WIN_MESSAGE_BANNER = 'createWinMessageBanner',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAY_TABLE = 'disablePayTable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  FORCE_STOP_REELS = 'forceStopReels',
  END_RETRIGGER_FEATURE = 'endRetriggerFeature',
  JINGLE_START = 'jingleStart',
  HIDE_COINS = 'hideCoins',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_LINES = 'hideWinLines',
  HIDE_STOP_SLOTS_DISPLAY = 'hideStopSlotsDisplay',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  MANUAL_DESTROY_MESSAGE_BANNER = 'manualDestroyMessageBanner',
  MANUAL_CHANGE_BACKGROUND = 'manualChangeBackground',
  REEL_STOPPED = 'reelStopped',
  REELS_STOPPED = 'reelsStopped',
  REGISTER_ANIMATOR = 'registerAnimator',
  RESET_SLOT_MACHINE = 'resetSlotMachine',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  REMOVE_FREE_SPINS_TITLE = 'removeFreeSpinsTitle',
  REMOVE_ANIMATOR = 'removeAnimator',
  ROLLBACK_REELS = 'rollbackReels',
  SLOT_MACHINE_STATE_CHANGE = 'slotMachineStateChange',
  SET_WIN_LABEL_TEXT = 'setWinLabelText',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_RETRIGGER_ANIMATION = 'startRetrigger',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_DIA_WIN_ANIMATION = 'startDiaWinAnimation',
  SHOW_WIN_LINES = 'showWinLines',
  SHOW_WIN_LABEL = 'showWinLabel',
  SHOW_STOP_SLOTS_DISPLAY = 'showStopSlotsDisplay',
  SHOW_TINT = 'showTint',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_COINS = 'showCoins',
  SET_BIG_WIN_VISIBILITY = 'setBigWinVisibility',
  SET_MEGA_WIN_VISIBILITY = 'setMegaWinVisibility',
  SET_GREAT_WIN_VISIBILITY = 'setGreatWinVisibility',
  SET_EPIC_WIN_VISIBILITY = 'setEpicWinVisibility',
  SETUP_REEL_POSITIONS = 'setupReelPositions',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_SLOTS_ANIMATION = 'skipWinSlotsAnimation',
  THROW_ERROR = 'throwError',
  REMOVE_ANTICIPATION_TINT = 'removeAnticipationTint',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  SET_IS_OPENED_MESSAGE_BANNER = 'isOpenedMessageBanner',
  SET_IS_IN_TRANSITION = 'isInTransition',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  SOUND_INITIALIZED = 'soundInitialized',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  OPEN_BUY_FEATURE_POPUP = 'openBuyFeaturePopup',
  OPEN_BUY_FEATURE_POPUP_BG = 'openBuyFeaturePopupBg',
  CLOSE_BUY_FEATURE_POPUP = 'closeBuyFeaturePopup',
  CLOSE_BUY_FEATURE_POPUP_BG = 'closeBuyFeaturePopupBg',
  OPEN_BUY_FEATURE_CONFIRM_POPUP = 'openBuyFeatureConfirmPopup',
  // FS
  GET_BINGO = 'getBingo',
  INITIALIZE_FREESPINS = 'initFreeSpins',
  SET_FREESPINS_STATE = 'setFreespinsState',
  RESET_FREESPINS_REMAIN = 'resetFreespinsRemain',
  END_FREESPINS = 'endFreeSpins',
  SET_FREESPINS_RESULT = 'setFreeSpinsResult',
  START_TRACK_ANIMATION = 'startTrackAnimation',
  SKIP_COLLECT_ANIMATION = 'skipCollectAnimation',
  SET_IS_DURING_COLLECT_ANIMATION = 'isDuringCollectAnimation',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  SET_REPLAY_BET_ID = 'setReplayBetId',

  // frb
  SET_IS_FREE_ROUND_BONUS = 'setIsFreeRoundBonus',
  SET_IS_POPUP_FREE_ROUNDS_OPENED = 'isOpenPopupFreeRounds',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE = 'updateFreeRoundBonusTotalWinValue',
  OPEN_POPUP_FREE_ROUNDS = 'openPopupFreeRounds',
  OPEN_POPUP_FREE_ROUNDS_END = 'openPopupFreeRoundsEnd',
  START_FADE_TRANSITION_FREE_ROUNDS_BONUS = 'startFadeTransitionFreeRoundBonus',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoPlay',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

export interface Events {
  [EventTypes.ADD_WIN_AMOUNT]: (winAmount: number) => void;
  [EventTypes.ANTICIPATION_STARTS]: (index: number) => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_START]: () => void;
  [EventTypes.ANTICIPATION_ANIMATIONS_END]: () => void;
  [EventTypes.BREAK_SPIN_ANIMATION]: () => void;
  [EventTypes.COUNT_UP_END]: () => void;
  [EventTypes.CHANGE_MODE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    scatterScores?: number[];
    background?: BgSkin;
    isRetrigger?: boolean;
  }) => void;
  [EventTypes.CHANGE_REEL_SET]: (settings: { reelSet: ReelSet; reelPositions: number[] }) => void;
  [EventTypes.CREATE_FREE_SPINS_TITLE]: (props: FreeSpinsTitleProps) => void;
  [EventTypes.CREATE_MESSAGE_BANNER]: (props: MessageFreeSpinsBannerProps) => void;
  [EventTypes.CREATE_WIN_MESSAGE_BANNER]: (props: MessageWinBannerProps) => void;
  [EventTypes.DISABLE_ALL_MINI_PAY_TABLES]: () => void;
  [EventTypes.DISABLE_PAY_TABLE]: (isVisible: boolean) => void;
  [EventTypes.END_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.FORCE_STOP_REELS]: (isTurboSpin: boolean) => void;
  [EventTypes.JINGLE_START]: () => void;
  [EventTypes.HIDE_COINS]: () => void;
  [EventTypes.HANDLE_CHANGE_RESTRICTION]: () => void;
  [EventTypes.HIDE_WIN_COUNT_UP_MESSAGE]: () => void;
  [EventTypes.HIDE_WIN_LABEL]: () => void;
  [EventTypes.HIDE_STOP_SLOTS_DISPLAY]: () => void;
  [EventTypes.HIDE_WIN_LINES]: (line: IWinLine[]) => void;
  [EventTypes.HANDLE_CHANGE_BET_AMOUNT]: (betAmount: number) => void;
  [EventTypes.HANDLE_SKIP_FADE_ANIMATION]: () => void;
  [EventTypes.HANDLE_START_FADE_ANIMATION]: (stage: number) => void;
  [EventTypes.NEXT_FREE_SPINS_ROUND]: () => void;
  [EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_DESTROY_MESSAGE_BANNER]: () => void;
  [EventTypes.MANUAL_CHANGE_BACKGROUND]: (settings: { mode: GameMode; background?: BgSkin }) => void;
  [EventTypes.REEL_STOPPED]: (reelId: number, stopSoundSymbolNo: number) => void;
  [EventTypes.REELS_STOPPED]: (isTurboSpin: boolean) => void;
  [EventTypes.REGISTER_ANIMATOR]: (animator: () => void, priority?: number) => void;
  [EventTypes.RESET_SLOT_MACHINE]: () => void;
  [EventTypes.RESIZE]: (width: number, height: number) => void;
  [EventTypes.RESIZE_GAME_CONTAINER]: (
    width: number,
    height: number,
    x: number,
    y: number,
    scale: number,
    pivotX: number,
    pivotY: number,
  ) => void;
  [EventTypes.REMOVE_TWEEN_ANIMATION]: (animation: Tween) => void;
  [EventTypes.REMOVE_FREE_SPINS_TITLE]: () => void;
  [EventTypes.REMOVE_ANIMATOR]: (animator: () => void) => void;
  [EventTypes.ROLLBACK_REELS]: (positions: number[]) => void;
  [EventTypes.SLOT_MACHINE_STATE_CHANGE]: (state: SlotMachineState) => void;
  [EventTypes.SET_WIN_LABEL_TEXT]: () => void;
  [EventTypes.START_TWEEN_ANIMATION]: (animation: Animation) => void;
  [EventTypes.START_WIN_ANIMATION]: (nextResult: ISettledBet, isTurboSpin: boolean) => void;
  [EventTypes.START_SPIN_ANIMATION]: (isTurboSpin?: boolean) => void;
  [EventTypes.START_RETRIGGER_ANIMATION]: () => void;
  [EventTypes.START_MODE_CHANGE_FADE]: (settings: {
    mode: GameMode;
    reelPositions: number[];
    reelSetId: string;
    fadeOutDuration: number;
    fadeInDuration: number;
    background?: BgSkin;
    scatterScores?: number[];
  }) => void;
  [EventTypes.START_DIA_WIN_ANIMATION]: () => void;
  [EventTypes.SHOW_WIN_LINES]: (lines: IWinLine[]) => void;
  [EventTypes.SHOW_WIN_LABEL]: () => void;
  [EventTypes.SHOW_STOP_SLOTS_DISPLAY]: (spinResult: Icon[], scatterScores?: number[], reelId?: number) => void;
  [EventTypes.SHOW_TINT]: (state: boolean, reelId?: number) => void;
  [EventTypes.SHOW_PAY_TABLE]: (i: number) => void;
  [EventTypes.SHOW_COINS]: () => void;
  [EventTypes.SET_BIG_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_MEGA_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_GREAT_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SET_EPIC_WIN_VISIBILITY]: (visible: boolean) => void;
  [EventTypes.SETUP_REEL_POSITIONS]: (
    reelPositions: Array<number>,
    scatterNo: Array<number>,
    anticipationStartReelId: number,
    scatterAnnounceType: number,
  ) => void;
  [EventTypes.SKIP_ALL_WIN_ANIMATIONS]: () => void;
  [EventTypes.SKIP_WIN_COUNT_UP_ANIMATION]: () => void;
  [EventTypes.SKIP_WIN_SLOTS_ANIMATION]: () => void;
  [EventTypes.THROW_ERROR]: () => void;
  [EventTypes.REMOVE_ANTICIPATION_TINT]: () => void;
  [EventTypes.UPDATE_BET]: (betAmount?: number) => void;
  [EventTypes.UPDATE_USER_BALANCE]: (balance?: { currency: string; amount: number }) => void;
  [EventTypes.UPDATE_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.UPDATE_WIN_VALUE]: (newValue: string) => void;
  [EventTypes.POST_RENDER]: () => void;
  [EventTypes.HANDLE_UPDATE_FREE_SPINS_TITLE]: (spins: number, curr: number, immediately: boolean) => void;
  [EventTypes.SET_IS_LEFT_HAND_MODE]: (isLeftHandMode: boolean) => void;
  [EventTypes.SET_IS_AUTO_SPINS]: (isAutoSpins: boolean) => void;
  [EventTypes.SET_AUTO_SPINS_LEFT]: (autoSpinsLeft: number) => void;
  [EventTypes.SET_IS_SPIN_IN_PROGRESS]: (isSpinInProgress: boolean) => void;
  [EventTypes.SET_IS_SLOT_IS_BUSY]: (isSlotBusy: boolean) => void;
  [EventTypes.SET_IS_SLOTS_STOPPED]: (isSlotStopped: boolean) => void;
  [EventTypes.SET_IS_FREESPINS_WIN]: (isFreeSpinWin: boolean) => void;
  [EventTypes.SET_IS_OPENED_MESSAGE_BANNER]: (isOpenedMessageBanner: boolean) => void;
  [EventTypes.SET_IS_IN_TRANSITION]: (isInTransition: boolean) => void;
  [EventTypes.TOGGLE_SOUND]: (isSoundOn: boolean) => void;
  [EventTypes.TOGGLE_TURBO_SPIN]: (isTurboSpin: boolean) => void;
  [EventTypes.TOGGLE_SPIN]: () => void;
  [EventTypes.HANDLE_DESTROY_INTRO_SCREEN]: () => void;
  [EventTypes.SOUND_INITIALIZED]: (isError: boolean) => void;
  [EventTypes.ENABLE_SOUND_LOADER]: () => void;
  // BuyFeature
  [EventTypes.START_BUY_FEATURE_ROUND]: (id: string) => void;
  [EventTypes.HANDLE_BUY_BONUS]: () => void;
  [EventTypes.DISABLE_BUY_FEATURE_BTN]: (disable: boolean) => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP]: () => void;
  [EventTypes.CLOSE_BUY_FEATURE_POPUP_BG]: () => void;
  [EventTypes.OPEN_BUY_FEATURE_CONFIRM_POPUP]: (totalCost: string, coinAmount: number) => void;
  [EventTypes.GET_BINGO]: (line: BingoLine) => void;
  [EventTypes.INITIALIZE_FREESPINS]: (data: BonusData) => void;
  [EventTypes.SET_FREESPINS_STATE]: (state: FreeSpinsState) => void;
  [EventTypes.END_FREESPINS]: () => void;
  [EventTypes.RESET_FREESPINS_REMAIN]: () => void;
  [EventTypes.START_TRACK_ANIMATION]: (start: IPointData, end: IPointData, duration: number, cb?: () => void) => void;
  [EventTypes.SKIP_COLLECT_ANIMATION]: () => void;
  [EventTypes.SET_FREESPINS_RESULT]: (score: number, mult: number, total: number) => void;
  [EventTypes.SET_IS_DURING_COLLECT_ANIMATION]: (isDuringCollectAnimation: boolean) => void;
  [EventTypes.FORCE_CLOSE_BUYFEATURE]: () => void;
  [EventTypes.SET_REPLAY_BET_ID]: () => void;

  [EventTypes.SET_IS_FREE_ROUND_BONUS]: (isFreeRoundBonus: boolean) => void;
  [EventTypes.SET_IS_POPUP_FREE_ROUNDS_OPENED]: (isPopupFreeRoundsOpened: boolean) => void;
  [EventTypes.UPDATE_FREE_ROUNDS_LEFT]: (rounds: number) => void;
  [EventTypes.UPDATE_FREE_ROUND_BONUS_TOTAL_WIN_VALUE]: (newValue: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS]: (rounds: number) => void;
  [EventTypes.OPEN_POPUP_FREE_ROUNDS_END]: (isExpired?: boolean) => void;
  [EventTypes.START_FADE_TRANSITION_FREE_ROUNDS_BONUS]: (settings: {
    outDuration: number;
    inDuration: number;
    callback: () => void;
  }) => void;
  [EventTypes.FORCE_STOP_AUTOPLAY]: () => void;
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}

export enum BgmSoundTypes {
  BASE = 'regular',
  FS = 'fs',
  FS2 = 'fs2',
}
